import React, { useState, useEffect, useRef } from 'react';
import '../styles/Sidebar.css';

const Sidebar = ({ onItemClick }) => {
  const [selectedItem, setSelectedItem] = useState('Dashboard');
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    onItemClick(item);
    setIsOpen(false);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      console.log('Clicked outside sidebar');
      setIsOpen(false);
    } else {
      console.log('Clicked inside sidebar');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const sidebarItems = [
    { name: 'Dashboard', icon: 'fas fa-tachometer-alt' },
    { name: 'Become a Member', icon: 'fas fa-id-card' },
    { name: 'Milestones Rewards', icon: 'fas fa-trophy' },
    { name: 'Transaction History', icon: 'fas fa-history' },
    { name: 'News', icon: 'fas fa-newspaper' },
    { name: 'Video Tutorials', icon: 'fas fa-video' },
    { name: 'Referral', icon: 'fas fa-user-plus' },
    { name: 'Support', icon: 'fas fa-headset' },
    { name: 'FAQ', icon: 'fas fa-question-circle' },
    { name: 'Terms & Conditions', icon: 'fas fa-file-contract' },
  ];

  return (
    <>
      <button className="sidebar-toggle" onClick={toggleSidebar}>
        <i className="fas fa-bars"></i>
      </button>
      <div ref={sidebarRef} className={`sidebar-container ${isOpen ? '' : 'closed'}`}>
        <div className="sidebar">
          <div className="sidebar-content">
            {sidebarItems.map((item) => (
              <div
                key={item.name}
                className={`sidebar-item ${selectedItem === item.name ? 'active' : ''}`}
                onClick={() => handleItemClick(item.name)}
              >
                <i className={item.icon}></i>
                <span className="item-name">{item.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
