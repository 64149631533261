// Dashboard.js
import React, { useCallback, useContext, useState, useEffect } from 'react';
import AffiliateSections from './AffiliateSections';
import MilestoneRewards from './MilestoneRewards';
import Membership from './Membership';
import AllTimeStats from './AllTimeStats';
import Web3 from 'web3';
import dataLabAbi from '../ABI/timeABI.json';
import { WalletContext } from '../WalletContext';
import '../styles/Dashboard.css';

function Dashboard() {
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const [dashbordVideo, setDashbordVideo] = useState();
  const { timeDateAddress } = useContext(WalletContext);

  const updateVideosUrl = useCallback(async () => {
    try {
      console.log(`Updating reward videos`);
      const web3Instance = new Web3('https://polygon-mainnet.infura.io/v3/2a8bbfb4590e4950a2b5013900024f08');
      const contractInstance = new web3Instance.eth.Contract(dataLabAbi, timeDateAddress);

      const videosNonce = await contractInstance.methods.videoNonce().call();
      const videosNonceNumber = Number(videosNonce.toString());
      let videoLink;

      for (let i = 1; i <= videosNonceNumber; i++) {
        const videosData = await contractInstance.methods.getVideoData(i).call();
        if (videosData.types === 'Dashboard Video') {
          videoLink = videosData.url;
        }
      }
      setDashbordVideo(videoLink);
      console.log('Reward videos updated:', videoLink);
    } catch (error) {
      console.error('Error updating reward videos:', error);
    }
  }, [timeDateAddress]);

  useEffect(() => {
    const fetchVideoData = async () => {
      await updateVideosUrl();
      setShowVideoPopup(true);
    };

    fetchVideoData();
  }, [updateVideosUrl]);

  const VideoPopup = ({ video, onClose }) => {
    return (
      <div className="video-popup-overlay">
        <div className="video-popup">
          <button className="popup-close-btn" onClick={onClose}>✕</button>
          <video src={dashbordVideo} controls autoPlay width="100%">
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    );
  };

  return (
    <div className='dash'>
      {showVideoPopup && (
        <VideoPopup
          video={dashbordVideo}
          onClose={() => setShowVideoPopup(false)}
        />
      )}
      <AffiliateSections />
      <MilestoneRewards />
      <Membership />
      <AllTimeStats />
    </div>
  );
}

export default Dashboard;