import React, { useContext, useState, useEffect, useCallback } from 'react';
import { WalletContext } from '../WalletContext';
import CustomAlert from './CustomAlert';
import loadingSpinner from '../assets/loading-spinner.gif';
import Bitcoinexplore from '../assets/membervideos/bitcoinexplore.mp4';
import Blockchainpro from '../assets/membervideos/blockchainpro.mp4';
import Metaversemaster from '../assets/membervideos/metaversemaster.mp4';
import Aiultimate from '../assets/membervideos/aiultimate.mp4';
import '../styles/Membership.css';

const cardData = [
  {
    title: 'BITCOIN EXPLORER',
    video: Bitcoinexplore,
    price: 500,
    features: {
      'Academy and IMTX Airdrop': true,
      'Digital Asset Portfolio': false,
      'Real Estate Investment Trust and Metaverse': false,
      'ImpactX Esports NFT': false,
      'Precious Metals': false,
    },
    ActiveFeaturs1: 'Academy and IMTX Airdrop',
  },
  {
    title: 'BLOCKCHAIN PRO',
    video: Blockchainpro,
    price: 1000,
    features: {
      'Academy and IMTX Airdrop': true,
      'Digital Asset Portfolio': true,
      'Real Estate Investment Trust and Metaverse': false,
      'ImpactX Esports NFT': false,
      'Precious Metals': false,
    },
    ActiveFeaturs1: 'Academy and IMTX Airdrop',
    ActiveFeaturs2: 'Digital Asset Portfolio',
  },
  {
    title: 'METAVERSE MASTER',
    video: Metaversemaster,
    price: 1500,
    features: {
      'Academy and IMTX Airdrop': true,
      'Digital Asset Portfolio': true,
      'Real Estate Investment Trust and Metaverse': true,
      'ImpactX Esports NFT': true,
      'Precious Metals': false,
    },
    ActiveFeaturs1: 'Academy and IMTX Airdrop',
    ActiveFeaturs2: 'Digital Asset Portfolio',
    ActiveFeaturs3: 'Real Estate Investment Trust and Metaverse',
    ActiveFeaturs4: 'ImpactX Esports NFT',
  },
  {
    title: 'AI ULTIMATE PRO',
    video: Aiultimate,
    price: 2000,
    features: {
      'Academy and IMTX Airdrop': true,
      'Digital Asset Portfolio': true,
      'Real Estate Investment Trust and Metaverse': true,
      'ImpactX Esports NFT': true,
      'Precious Metals': true,
    },
    ActiveFeaturs1: 'Academy and IMTX Airdrop',
    ActiveFeaturs2: 'Digital Asset Portfolio',
    ActiveFeaturs3: 'Real Estate Investment Trust and Metaverse',
    ActiveFeaturs4: 'ImpactX Esports NFT',
    ActiveFeaturs5: 'Precious Metals',
  },
];

const Membership = () => {
  const [ownedTokens, setOwnedTokens] = useState([]);
  const [nftData, setNftData] = useState([]);
  const [showVIPCards, setShowVIPCards] = useState(false);
  
  const [selectedCard, setSelectedCard] = useState(null);
  const [cardType, setCardType] = useState();
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [explorerLink, setExplorerLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [cards, setNumberOfMembership] = useState(1);
  const { web3, account, isConnected,
    referee, contract, usdtContract,
    isApproved, setApproved,
    setBitcoinExplorerMember, setBlockchainProMember,
    setMetaverseMasterMember, setAiUltimateProMember,
    impactXAddress, tokenAddress, nftContract
  } = useContext(WalletContext);

  const showCustomAlert = (message, explorerLink = '') => {
    setAlertMessage(message);
    setExplorerLink(explorerLink);
    setShowAlert(true);
  };

  const closeCustomAlert = () => {
    setShowAlert(false);
  };

  const fetchOwnedTokens = useCallback(async () => {
    if (!nftContract || !account) {
      console.log('nftContract or account not available');
      return;
    }
    try {
      console.log(`Checking membership for account: ${account}`);    
      const tokens = await nftContract.methods.getOwnedTokens(account).call();
      console.log(`Owned Tokens: ${tokens}`);
      setOwnedTokens(tokens);
      fetchNFTData(tokens);
    } catch (error) {
      console.error('Error fetching owned tokens:', error);
    }
  });

  useEffect(() => {
    if (isConnected && account) {
      fetchOwnedTokens();
    }
  }, [isConnected, account, nftContract, fetchOwnedTokens]);

  const fetchNFTData = async (tokens) => {
    try {
      const metadataPromises = tokens.map(async (tokenId) => {
        const uri = await nftContract.methods.tokenURI(tokenId).call();
        const response = await fetch(uri);
        return response.json();
      });
      const metadata = await Promise.all(metadataPromises);
      setNftData(metadata);
    } catch (error) {
      console.error('Error fetching NFT data:', error);
    }
  };

  const renderNFTContent = (nft) => {
    if (!nft) return null;

    if (nft.image && nft.image.endsWith('.mp4')) {
      return (
        <video width="100%" controls autoPlay loop>
          <source src={nft.image} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    } else if (nft.image) {
      return <img src={nft.image} alt="NFT" style={{ maxWidth: '100%' }} />;
    }

    return null;
  };

  const getMembershipLevel = (nft) => {
    const membershipLevelAttribute = nft.attributes.find(attr => attr.trait_type === "Membership Level");
    return membershipLevelAttribute ? membershipLevelAttribute.value : "Unknown";
  };

  const renderTraits = (nft) => {
    return nft.attributes
      .filter(attr => attr.trait_type !== "Membership Level" && attr.value === "Yes")
      .map((attr, index) => (
        <li key={index}>{attr.trait_type}</li>
      ));
  };  

  const handleViewCards = (card, cardPrice) => {
    setSelectedCard(card);
    if (cardPrice === 500) {
      setCardType(1);
    } else if (cardPrice === 1000) {
      setCardType(2);
    } else if (cardPrice === 1500) {
      setCardType(3);
    } else {
      setCardType(4);
    }
  };

  const getDecimalPrecision = async () => {
    if (web3) {
      try {
        const precision = await usdtContract.methods.decimals().call();
        return parseInt(precision);
      } catch (error) {
        console.error('Error fetching decimal precision:', error);
        return 18; // Default to 18 if unable to fetch precision
      }
    } else {
      console.error('Web3 instance not available.');
      return 18; // Default to 18 if web3 instance is not available
    }
  };
  
  const returnApprovedAmount = async () => {
    if (web3 && contract) {
      const allowance = await usdtContract.methods.allowance(account, impactXAddress).call();
      return allowance;
    }
    return 0;
  };

  const handleTransactionStart = () => {
    setIsLoading(true);
    console.log(`Transection started..`);
  };

  const handleTransactionEnd = (transactionHash, network) => {
    if (transactionHash) {
      const explorerLink = `https://polygonscan.com/tx/${transactionHash}`;  
      showCustomAlert(`Transaction confirmed!`, explorerLink);
    }
    setIsLoading(false);
    console.log(`Transection end..`);
  };

  const getButtonText = () => {
    if (isApproved) {
      return `Join Now`;
    } else {
      return 'Enable To Join';
    }
  };

  const approveToken = async () => {
    if (web3 && contract) {
      try {
        const amountToApprove = web3.utils.toWei(selectedCard.price * cards, 'ether');
        const allowance = await usdtContract.methods.allowance(account, impactXAddress).call();
        if (allowance >= amountToApprove) {
          setApproved(true);
          getButtonText();
          await checkMembership();
        } else {
          if (selectedCard.price > 0) {
            handleTransactionStart();
          }
          const txHash = await usdtContract.methods.approve(impactXAddress, amountToApprove).send({
            from: account
          });
          handleTransactionEnd(txHash.transactionHash, 'eth');
          const currentAllowance = await usdtContract.methods.allowance(account, impactXAddress).call();
          if (currentAllowance > 0) {
            setApproved(true);
            getButtonText();
            await checkMembership();
            console.log(`${amountToApprove.toUpperCase()} approved successfully.`);
          } else {
            console.log(`Unable to approvel statuse.`);
          }
        }
      } catch (error) {
        console.error(`Error approving ${tokenAddress.toUpperCase()}:`, error);
        handleTransactionEnd();
      }
    } else {
      showCustomAlert('Please connect your wallet first.');
      console.error('Please connect your wallet first.');
    }
    handleTransactionEnd();
  };

  const enrollMembership = async () => {
    if (isApproved) {
      if (web3 && contract) {
        try {
          // Check if the user not connected
          if (!isConnected) {
            showCustomAlert('Please connect with wallet.');
            return;
          }
          const decimal = await getDecimalPrecision();
          const amount = web3.utils.toWei(selectedCard.price * cards, 'ether');
          const approvedAmount = await returnApprovedAmount();
          console.log(decimal, amount, approvedAmount, account);
          handleTransactionStart();
          if (approvedAmount >= amount) {
            console.log('Going to buy Mambership using usdt.');
            // Updated exchangeToken function call with
            const txHash = await contract.methods.purchaseMembership(referee, cardType, cards).send({
              from: account
            });
            console.log(`Deposited successfully`);
            handleTransactionEnd(txHash.transactionHash, 'eth');
            await checkMembership();
          } else {
            console.log('Please approve the token first.');
            approveToken();
          }
        } catch (error) {
          console.error(`Error buying mambership:`, error);
          handleTransactionEnd();
        }
      } else {
        showCustomAlert('Please connect your wallet first.');
        console.error('Please connect your wallet first.');
      }
    } else {
      console.log('Please approve the token first.');
      approveToken();
    }
  };

  const checkMembership = useCallback(async () => {
    if (!nftContract || !account) {
      console.log('nftContract or account not available');
      return;
    }
    try {
      console.log(`Checking membership for account: ${account}`);
      
      // Get all owned tokens for the account
      const ownedTokens = await nftContract.methods.getOwnedTokens(account).call();
      console.log(`Owned Tokens: ${ownedTokens}`);
  
      let bitcoinExplorer = 0;
      let blockchainPro = 0;
      let metaverseMaster = 0;
      let aiUltimatePro = 0;
      
      // Iterate through owned tokens
      for (let tokenId of ownedTokens) {
        const character = await nftContract.methods.characterOf(tokenId).call();
        
        switch (parseInt(character)) {
          case 1:
            bitcoinExplorer++;
            break;
          case 2:
            blockchainPro++;
            break;
          case 3:
            metaverseMaster++;
            break;
          case 4:
            aiUltimatePro++;
            break;
          default:
            console.warn(`Unknown character type for token ${tokenId}: ${character}`);
        }
      }
  
      console.log(`Bitcoin Explorer: ${bitcoinExplorer}`);
      console.log(`Blockchain Pro: ${blockchainPro}`);
      console.log(`Metaverse Master: ${metaverseMaster}`);
      console.log(`AI Ultimate Pro: ${aiUltimatePro}`);
  
      setBitcoinExplorerMember(bitcoinExplorer);
      setBlockchainProMember(blockchainPro);
      setMetaverseMasterMember(metaverseMaster);
      setAiUltimateProMember(aiUltimatePro);
  
    } catch (error) {
      console.error('Error checking membership:', error);
    }
  }, [nftContract, account, setBitcoinExplorerMember, setBlockchainProMember, setMetaverseMasterMember, setAiUltimateProMember]);

  const renderFeatures = (card) => {
    return (
      <ul className="feature-list">
        {Object.entries(card.features).map(([feature, included]) => (
          <li key={feature} className={included ? 'active-feature' : 'inactive-feature'}>
            {feature}
            <span className={included ? 'checkmark' : 'cross'}>
              {included ? '✓' : '✗'}
            </span>
          </li>
        ))}
      </ul>
    );
  };
  
  const renderCardDetails = () => {
    if (!selectedCard) return null;
  
    return (
      <div className="card-details-overlay">
        <div className="card-details">
          <h2>VIP membership cards details</h2>
          <div className="card-details-content">
            <div className="card-image">
              <div className="placeholder-image">
                <video src={selectedCard.video} controls autoPlay loop width="100%" height="100%" />
              </div>
            </div>
            <div className="card-info">
              <h3>{selectedCard.title}</h3>
              <div className='price-number'>
                <p className="card-price">${selectedCard.price}</p>
                <input 
                  className='card-input' 
                  type='number' 
                  placeholder='Number of card' 
                  value={cards} 
                  onChange={(e) => setNumberOfMembership(e.target.value)} 
                />
              </div>
              {renderFeatures(selectedCard)}
              <button className="join-now-btn" onClick={enrollMembership}>
                {getButtonText()}
              </button>
            </div>
          </div>
          <button className="close-btn" onClick={() => setSelectedCard(null)}>✕</button>
        </div>
        {showAlert && (
          <CustomAlert 
            message={alertMessage} 
            explorerLink={explorerLink} 
            onClose={closeCustomAlert} 
          />
        )}
        {isLoading && (
          <div className="loading-overlay">
            <div className="loading-spinner">
              <img src={loadingSpinner} alt="Loading..." />
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderVIPCards = () => {
    const cards = [
      { name: "BITCOIN EXPLORER", price: 500, features: ["Academy and IMTX Airdrop"] },
      { name: "BLOCKCHAIN PRO", price: 1000, features: ["Academy and IMTX Airdrop", "Digital Asset Portfolio"] },
      { name: "METAVERSE MASTER", price: 1500, features: ["Academy and IMTX Airdrop", "Digital Asset Portfolio", "Real Estate Investment Trust and Metaverse", "ImpactX Esports NFT"] },
      { name: "AI ULTIMATE PRO", price: 2000, features: ["Academy and IMTX Airdrop", "Digital Asset Portfolio", "Real Estate Investment Trust and Metaverse", "ImpactX Esports NFT", "Precious Metals"] }
    ];

    return (
      <div>
        <div className="vip-membership-cards">
          <h2>Buy VIP Membership Cards</h2>
          <div className="cards-container">
            {cardData.map((card, index) => (
              <div key={index} className="card">
                <h3>{card.title}</h3>
                <p className="price">${card.price}</p>
                <button className="view-cards-btn" onClick={() => handleViewCards(card, card.price)}>
                  View cards
                </button>
                <ul className="features">
                  {Object.entries(card.features).map(([feature, included], i) => (
                    <li key={i} className={included ? 'active-feature' : 'inactive-feature'}>
                      {feature}
                      <span className={included ? 'checkmark' : 'cross'}>
                        {included ? '✓' : '✗'}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          {renderCardDetails()}
        </div>
      </div>
    );
  };

  return (
    <div className="membership">
      {!showVIPCards ? (
        <>
          <h2>MEMBERSHIP</h2>
          <div className="membership-content">
            {ownedTokens.length > 0 ? (
              <>
                {nftData.map((nft, index) => (
                  <div key={index} className="nft-item">
                    <h3>{getMembershipLevel(nft)}</h3>
                    {renderNFTContent(nft)}
                    <ul className="traits-list">
                      {renderTraits(nft)}
                    </ul>
                  </div>
                ))}
              </>
            ) : (
              <>
                <span className="no-membership">No membership</span>
                <p>Get your Membership Card to get started.</p>
                <button className="join-membership" onClick={() => setShowVIPCards(true)}>Become a Member</button>
              </>
            )}
          </div>
        </>
      ) : (
        renderVIPCards()
      )}
      {renderCardDetails()}
    </div>
  );
};

export default Membership;