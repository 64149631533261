// App.js
import { React, useState } from 'react';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import { WalletProvider } from './WalletContext';
import Dashboard from './components/Dashbord';
import MembershipCards from './components/VIPMembershipCards';
import MilestonesRewardsCards from './components/MilestonesRewardsCards';
import TransactionHistory from './components/TransactionHistory';
import News from './components/News';
import VideoTutorials from './components/Video';
import ReferralNode from './components/ReferralNode';
import ContactForm from './components/ContactForm';
import AskQuickQuestion from './components/AskQuickQuestion';
import TermsAndConditions from './components/TermsAndConditions';
// import Footer from './components/Footer'
import './App.css';

function App() {
  const [selectedItem, setSelectedItem] = useState('Dashboard');

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <WalletProvider>
      <div className="app" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <div style={{ display: 'flex', flex: 1 }}>
          <Sidebar onItemClick={handleItemClick} />
          <div className="main-content" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Header />
            <div style={{ flex: 1 }}>
              {selectedItem === 'Dashboard' && <Dashboard />}
              {selectedItem === 'Become a Member' && <MembershipCards />}
              {selectedItem === 'Milestones Rewards' && <MilestonesRewardsCards />}
              {selectedItem === 'Transaction History' && <TransactionHistory />}
              {selectedItem === 'News' && <News />}
              {selectedItem === 'Video Tutorials' && <VideoTutorials />}
              {selectedItem === 'Referral' && <ReferralNode />}
              {selectedItem === 'Support' && <ContactForm />}
              {selectedItem === 'FAQ' && <AskQuickQuestion />}
              {selectedItem === 'Terms & Conditions' && <TermsAndConditions />}
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </WalletProvider>
  );
};

export default App;