import React, { useState, useCallback, useContext, useEffect } from 'react';
import Web3 from 'web3';
import { WalletContext } from '../WalletContext';
import dataLabAbi from '../ABI/timeABI.json';
import '../styles/AskQuickQuestion.css';

const QuestionCard = ({ question, answer, isOpen, onClick }) => {
  return (
    <div
      className={`question-card ${isOpen ? 'question-card-open' : 'question-card-closed'}`}
      onClick={onClick}
    >
      <div className="question-card-content">
        <h3 className="question-text">{question}</h3>
        <span className="question-arrow">{isOpen ? '▼' : '▲'}</span>
      </div>
      <div className="answer" dangerouslySetInnerHTML={{ __html: answer }} />
    </div>
  );
};

const AskQuickQuestion = () => {
  const [openQuestion, setOpenQuestion] = useState(null);
  const [questions, setQuestions] = useState([]);
  const { timeDateAddress } = useContext(WalletContext);

  const updateFaq = useCallback(async () => {
    try {
      console.log(`Updating FAQ`);
      const web3Instance = new Web3('https://polygon-mainnet.infura.io/v3/2a8bbfb4590e4950a2b5013900024f08');
      const contractInstance = new web3Instance.eth.Contract(dataLabAbi, timeDateAddress);

      const faqNonce = await contractInstance.methods.faqNonce().call();
      const faqNonceNumber = Number(faqNonce.toString());

      let allQuestions = [];

      for (let i = 1; i <= faqNonceNumber; i++) {
        const faqData = await contractInstance.methods.getFaqData(i).call();

        const questionAnswer = {
          question: faqData.question,
          answer: faqData.answer,
        };

        allQuestions.push(questionAnswer);
      }

      setQuestions(allQuestions);
      console.log('FAQ updated:', allQuestions);
    } catch (error) {
      console.error('Error updating FAQ:', error);
    }
  }, [timeDateAddress]);

  useEffect(() => {
    updateFaq();
  }, [updateFaq]);

  const handleQuestionClick = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  return (
    <div className="ask-quick-question-container">
      <h1 className="ask-quick-question-title">Frequently asked questions</h1>
      <div className="question-list">
        {questions.map((item, index) => (
          <QuestionCard
            key={index}
            question={item.question}
            answer={item.answer}
            isOpen={openQuestion === index}
            onClick={() => handleQuestionClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default AskQuickQuestion;