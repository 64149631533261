import React, { useContext, useEffect, useState, useCallback } from 'react';
import Web3 from 'web3';
import dataLabAbi from '../ABI/timeABI.json';
import { WalletContext } from '../WalletContext';
import logo from '../assets/MaleLogo.png';
import '../styles/ReferralNode.css';

const ReferralNode = ({ address, level = 0, onExpand, expandedNodes, currentPath }) => {
  const [referrals, setReferrals] = useState([]);
  const { contract, joiningDate, totalEarned, setTotalEarned, usdtDecimal } = useContext(WalletContext);

  const expanded = expandedNodes.has(address);

  useEffect(() => {
    fetchReferralData();
  }, [contract]);

  const fetchReferralData = async () => {
    try {
      const referralAddresses = await contract.methods.getReferrals(address).call();
      const _totalEarned = await contract.methods.getTotalEarning(address).call();
      const earnedTotal = _totalEarned.toString() / (10 ** usdtDecimal);
      setReferrals(referralAddresses);
      setTotalEarned(earnedTotal.toFixed(2));
    } catch (error) {
      console.error('Error fetching referral data:', error);
    }
  };

  const handleExpand = () => {
    onExpand(address);
  };

  const truncateAddress = (addr) => {
    if (typeof addr !== 'string') return 'Invalid Address';
    return `${addr.slice(0, 6)}...${addr.slice(-4)}`;
  };

  const isInPath = currentPath.includes(address);

  return (
    <div className={`referral-node level-${level}`}>
      <div className="node-content" onClick={handleExpand}>
        <img src={logo} alt="Referee Logo" className="avatar" />
        <div className="node-details">
          <div className="address">{truncateAddress(address)}</div>
          <div>Earned: ${totalEarned}</div>
          <div>Date: {joiningDate}</div>
        </div>
      </div>
      {(expanded || isInPath) && referrals.length > 0 && (
        <div className="children">
          {referrals.map((referralAddress, index) => (
            <ReferralNode
              key={index}
              address={referralAddress}
              level={level + 1}
              onExpand={onExpand}
              expandedNodes={expandedNodes}
              currentPath={currentPath}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const ReferralTree = () => {
  const { contract, account, timeDateAddress } = useContext(WalletContext);
  const [userReferrals, setUserReferrals] = useState([]);
  const [expandedNodes, setExpandedNodes] = useState(new Set());
  const [currentPath, setCurrentPath] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [videosUrl, setVideosUrl] = useState();

  useEffect(() => {
    fetchUserReferrals();
  }, [contract, account]);

  const fetchUserReferrals = async () => {
    try {
      setIsLoading(true);
      const refereeAddresses = await contract.methods.getReferrals(account).call();
      setUserReferrals(refereeAddresses);
    } catch (error) {
      console.error('Error fetching user referrals:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExpand = (address) => {
    if (currentPath.length === 0) {
      setCurrentPath([address]);
      setExpandedNodes(new Set([address]));
    } else {
      setCurrentPath((prevPath) => [...prevPath, address]);
      setExpandedNodes((prevExpanded) => new Set([...prevExpanded, address]));
    }
  };

  const handleBack = () => {
    setCurrentPath([]);
    setExpandedNodes(new Set());
  };

  const updateVideoUrl = useCallback(async () => {
    try {
      console.log(`Updating referral videos`);
      const web3Instance = new Web3('https://polygon-mainnet.infura.io/v3/2a8bbfb4590e4950a2b5013900024f08');
      const contractInstance = new web3Instance.eth.Contract(dataLabAbi, timeDateAddress);

      const videosNonce = await contractInstance.methods.videoNonce().call();
      const videosNonceNumber = Number(videosNonce.toString());
      let videoUrl;

      for (let i = 1; i <= videosNonceNumber; i++) {
        const videosData = await contractInstance.methods.getVideoData(i).call();

        if (videosData.types === 'Referral Video') {
          videoUrl = videosData.url;
          console.log('Nonce number:', videosData.videoNonce);
        }
      }
      setVideosUrl(videoUrl);
      console.log('Referral videos updated:', videoUrl);

    } catch (error) {
      console.error('Error updating referall videos:', error);
    }
  }, [timeDateAddress]);

  useEffect(() => {
    updateVideoUrl();
  }, [updateVideoUrl]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (userReferrals.length === 0) {
    return (
      <div className="referral-video-container">
        <div className="youtube-video">
        <iframe width="679" height="382" src={videosUrl} title="How to get started with STRBOON." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>
    );
  }

  return (
    <div className="referral-tree-container">
      {currentPath.length > 0 && (
        <button className="back-button" onClick={handleBack}>
          Back To Home
        </button>
      )}
      {userReferrals.map((address, index) => (
        <ReferralNode
          key={index}
          address={address}
          onExpand={handleExpand}
          expandedNodes={expandedNodes}
          currentPath={currentPath}
        />
      ))}
    </div>
  );
};

export default ReferralTree;
