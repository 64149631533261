import React from 'react';
import '../styles/DecentralizedApps.css';
import image1 from '../assets/dapp/1.jpg'
import image2 from '../assets/dapp/2.jpg'
import image3 from '../assets/dapp/3.jpg'
import image4 from '../assets/dapp/4.jpg'
import image5 from '../assets/dapp/5.jpg'
import image6 from '../assets/dapp/6.jpg'
import image7 from '../assets/dapp/7.jpg'
import image8 from '../assets/dapp/8.jpg'
import image9 from '../assets/dapp/9.jpg'
import image10 from '../assets/dapp/10.jpg'
import image11 from '../assets/dapp/11.jpg'
import image12 from '../assets/dapp/12.jpg'
import image13 from '../assets/dapp/13.jpg'
import image14 from '../assets/dapp/14.jpg'
import image15 from '../assets/dapp/15.jpg'

const DecentralizedApps = () => {
  return (
    <div className="decentralized-apps">
      <h1>ImpactX Decentralized Apps</h1>
      <div className="app-grid">
        <div className="app-card">
          <img src={image1} alt="Blockchain and AI" />
          <h2>Blockchain and AI Consultancy</h2>
          <p>We offer tailored consultancy, integrating AI and Blockchain for operational optimization and business growth.</p>
        </div>
        <div className="app-card">
          <img src={image2} alt="Esports Tournaments" />
          <h2>Esports Tournaments</h2>
          <p>Level up with esports tournaments at IMPACTX centers, fueled by IMTX tokens. Join gamers for competitive fun and rewards, all powered by blockchain technology.</p>
        </div>
        <div className="app-card">
          <img src={image3} alt="Emerging Tech Education" />
          <h2>Emerging Tech Education</h2>
          <p>At IMPACTX centers, dive into the future with our emerging tech education. Explore AI, blockchain, and more, all taught by industry experts for hands-on learning and real-world skills.</p>
        </div>
      </div>
      <div className="app-grid">
        <div className="app-card">
          <img src={image4} alt="ChainTrack Pro" />
          <h2>ChainTrack Pro</h2>
          <p>Seamlessly trace products from origin to destination with ChainTrack Pro. IMTX tokens power this supply chain solution, ensuring transparency and efficiency.</p>
        </div>
        <div className="app-card">
          <img src={image5} alt="FlavorFunds" />
          <h2>FlavorFunds</h2>
          <p>Share your taste, earn rewards with FlavorFunds. IMTX tokens incentivize feedback on products, creating a community-driven marketplace for taste exploration.</p>
        </div>
        <div className="app-card">
          <img src={image6} alt="SoundScape NFTs" />
          <h2>SoundScape NFTs</h2>
          <p>Explore the world of audio NFTs with SoundScape. Creators mint unique tokens on Solana, while users engage, collect, and trade exclusive audio experiences with IMTX tokens.</p>
        </div>
      </div>
      <div className="app-grid">
        <div className="app-card">
          <img src={image7} alt="LearnVantage" />
          <h2>LearnVantage</h2>
          <p>Unlock knowledge, earn rewards with LearnVantage. IMTX tokens drive this educational platform, offering interactive courses and incentives for learning.</p>
        </div>
        <div className="app-card">
          <img src={image8} alt="StakeWave" />
          <h2>StakeWave</h2>
          <p>Secure the future, shape the ecosystem with StakeWave. IMTX staking and governance dApp empower users to earn rewards and participate in network decisions.</p>
        </div>
        <div className="app-card">
          <img src={image9} alt="PlayParks" />
          <h2>PlayParks</h2>
          <p>Game, earn, repeat with PlayParks. IMTX-powered gaming dApp rewards players for skill, dedication, and engagement in a world of immersive experiences.</p>
        </div>
      </div>
      <div className="app-grid">
        <div className="app-card">
          <img src={image10} alt="EcoQuest Adventures" />
          <h2>EcoQuest Adventures</h2>
          <p>Embark on a journey to earn with EcoQuest Adventures. IMTX tokens fuel this play-to-earn game, where players explore, conquer challenges, and reap rewards.</p>
        </div>
        <div className="app-card">
          <img src={image11} alt="CoinTrack360" />
          <h2>CoinTrack360</h2>
          <p>Stay ahead of the curve with CoinTrack360. IMTX token integration provides real-time cryptocurrency portfolio tracking and insights for savvy investors.</p>
        </div>
        <div className="app-card">
          <img src={image12} alt="RemitTokens" />
          <h2>RemitTokens</h2>
          <p>Send funds globally, earn with RemitTokens. IMTX-enabled remittance dApp offers fast, lowcost transfers and rewards for cross-border transactions.</p>
        </div>
      </div>
      <div className="app-grid">
        <div className="app-card">
          <img src={image13} alt="PropTokenize" />
          <h2>PropTokenize</h2>
          <p>Fractional ownership made easy. IMTX tokens enable property tokenization, unlocking liquidity and diversification in real estate.</p>
        </div>
        <div className="app-card">
          <img src={image14} alt="IdentityGuard Registry" />
          <h2>IdentityGuard Registry</h2>
          <p>Secure land, verify identity with LandGuard ID. IMTX tokens enable transparent land registration and identity verification on the blockchain, ensuring authenticity in property transactions.</p>
        </div>
        <div className="app-card">
          <img src={image15} alt="PowerPulse Exchange" />
          <h2>PowerPulse Exchange</h2>
          <p>Energize your assets, trade sustainably with PowerPulse Exchange. IMTX tokens facilitate peer-to-peer energy trading and renewable energy certificate exchange on Solana's blockchain.</p>
        </div>
      </div>
    </div>
  );
};

export default DecentralizedApps;