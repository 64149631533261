import React, { useState, useEffect } from 'react';
import '../styles/News.css';

const News = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "get",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://v1.nocodeapi.com/rahaman78692/medium/rUKWpBzUYEKiOTEf", requestOptions)
      .then(response => response.json())
      .then(result => {
        setPosts(result);
      })
      .catch(error => console.log('error', error));
  }, []);

  if (!posts.length) {
    return <p className='p-text'>Loading posts...</p>;
  }

  return (
    <div className="container">
      <div className="post-grid">
        {posts.map((post, index) => (
          <div key={post.id} className="post-card">
            <h2 className="title">{post.title}</h2>
            {index === 0 ? (
              <div className="excerpt" dangerouslySetInnerHTML={{ __html: post.content.substring(0, 10000000) }}></div>
            ) : (
              <div className="excerpt" dangerouslySetInnerHTML={{ __html: post.content.substring(0, 1000) }}></div>
            )}
            <a href={post.link} target="_blank" rel="noopener noreferrer" className="read-more">
              Read more
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default News;
