import React, { useContext, useEffect, useState } from 'react';
import { WalletContext } from '../WalletContext';
import '../styles/MilestoneRewards.css';

const MilestoneRewards = () => {
  const { milestonRewards, bitcoinExplorerMember, blockchainProMember, metaverseMasterMember, aiUltimateProMember } = useContext(WalletContext);
  const [progressValue, setProgressValue] = useState(0);

  const convertBigIntToString = (value) => {
    return value ? value.toString() : '0';
  };

  const maxAmount = 10000000;
  const currentAmount = Math.min(parseInt(milestonRewards) || 0, maxAmount);
  let progress = (currentAmount / maxAmount) * 100;

  if (progress !== 0 && progress < 0.1) {
    progress = 1;
  }

  useEffect(() => {
    setProgressValue(progress);
  }, [progress]);

  const milestones = [
    { name: 'Bitcoin Explorer', memberships: convertBigIntToString(bitcoinExplorerMember) },
    { name: 'Blockchain Pro', memberships: convertBigIntToString(blockchainProMember) },
    { name: 'Metaverse Master', memberships: convertBigIntToString(metaverseMasterMember) },
    { name: 'AI Ultimate', memberships: convertBigIntToString(aiUltimateProMember) },
  ];

  return (
    <div className="milestone-rewards">
      <div className='progress-container'>
        <div role="progressbar" aria-valuenow={progressValue} aria-valuemin="0" aria-valuemax="100" style={{ '--value': progressValue }}>
          <span className="current-amount">${currentAmount.toLocaleString()}</span>
        </div>
        <h1 className='milestone-text'>Milestone</h1>
      </div>
      <div className="milestone-list">
        {milestones.map((milestone, index) => (
          <div key={index} className="milestone-item">
            <div className="milestone-name">{milestone.name}</div>
            <div className="milestone-memberships">{milestone.memberships}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MilestoneRewards;
