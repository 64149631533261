import React, { useContext } from 'react';
import '../styles/AffiliateSections.css';
import { WalletContext } from '../WalletContext';

const AffiliateSections = () => {
  const { account } = useContext(WalletContext);

  const referralURL = account
    ? `https://enter.impactxpro.com/referral/${account}`
    : '';

  const copyLink = () => {
    if (referralURL) {
      navigator.clipboard.writeText(referralURL)
        .then(() => {
          console.log('Referral URL copied to clipboard');
        })
        .catch((err) => {
          console.error('Failed to copy referral URL: ', err);
        });
    } else {
      console.error('No referral URL available');
    }
  };

  return (
    <div className="affiliate-sections">
      <div className="referral-url-section">
        <h2>Referral URL</h2>
        <p>Share your referral URL with your audience to earn commission</p>
        <div className="referral-url-container">
          {account ? (
            <>
              <input type="text" value={referralURL} readOnly />
              <button onClick={copyLink}>Copy link</button>
            </>
          ) : (
            <p>Please connect your wallet to get a referral URL</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AffiliateSections;