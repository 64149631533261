import React, { useState, useEffect, useContext } from 'react';
import { WalletContext } from '../WalletContext';
import '../styles/TransactionHistory.css';

const TransactionHistory = () => {
  const { account, isConnected, timeDateContract, usdtDecimal } = useContext(WalletContext);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    console.log('Component mounted. Checking connection:', isConnected);
    console.log('Account:', account);
    console.log('TimeDateContract:', timeDateContract);

    if (isConnected && timeDateContract) {
      fetchTransactions();
    }
  }, [isConnected, timeDateContract, account]);

  const fetchTransactions = async () => {
    try {
      console.log('Fetching transactions...');
      const taxNumbers = await timeDateContract.methods.getTaxNumbers(account).call();
      console.log('Tax numbers:', taxNumbers);

      const fetchedTransactions = await Promise.all(taxNumbers.map(async (taxNum) => {
        console.log('Fetching data for tax number:', taxNum);
        const taxData = await timeDateContract.methods.getTaxData(taxNum).call();
        console.log('Tax data:', taxData);

        const purpose = taxData.types.toString() === '1' ? 'Purchase' : 'Referral';
        const date = new Date(
          Number(taxData.year),
          Number(taxData.month) - 1,
          Number(taxData.day),
          Number(taxData.hour) - 1,
          Number(taxData.minute),
          Number(taxData.second)
        );
        console.log('Date:', Number(taxData.year), Number(taxData.month), Number(taxData.day), Number(taxData.hour), Number(taxData.minute), Number(taxData.second));
        const formattedDate = date.toUTCString();
        const taxDataAmount = Number(taxData.amount);

        return {
          purpose,
          amount: taxDataAmount / (10 ** usdtDecimal),
          date: formattedDate
        };
      }));

      console.log('Fetched transactions:', fetchedTransactions);
      setTransactions(fetchedTransactions);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  return (
    <div className="transaction-history">
      <div className="tab">Transactions History</div>
      <div className="table-container">
        <div className="table-header">
          <div className="header-cell">Purpose</div>
          <div className="header-cell">Amount</div>
          <div className="header-cell">Date</div>
        </div>
        <div className="table-body">
          {transactions.length > 0 ? (
            transactions.map((tx, index) => (
              <div className="table-row" key={index}>
                <div className="cell" data-label="Purpose">{tx.purpose}</div>
                <div className="cell" data-label="Amount">$ {tx.amount}</div>
                <div className="cell" data-label="Date">{tx.date}</div>
              </div>
            ))
          ) : (
            <div className="table-row">
              <div className="cell" colSpan="4">No Data Found</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;