import React, { useCallback, useContext, useEffect, useState } from 'react';
import Web3 from 'web3';
import dataLabAbi from '../ABI/timeABI.json';
import { WalletContext } from '../WalletContext';
import ImpactXLogo from '../assets/logoblack.png';
import '../styles/Video.css';

const App = () => {
  const [tutorialVideos, setTutorialVideos] = useState([]);
  const { timeDateAddress } = useContext(WalletContext);

  const updateVideosUrl = useCallback(async () => {
    try {
      console.log(`Updating tutorial videos`);
      const web3Instance = new Web3('https://polygon-mainnet.infura.io/v3/2a8bbfb4590e4950a2b5013900024f08');
      const contractInstance = new web3Instance.eth.Contract(dataLabAbi, timeDateAddress);

      const videosNonce = await contractInstance.methods.videoNonce().call();
      const videosNonceNumber = Number(videosNonce.toString());

      let allVideos = [];

      for (let i = 1; i <= videosNonceNumber; i++) {
        const videosData = await contractInstance.methods.getVideoData(i).call();
        let video = {
          title: videosData.title,
          description: videosData.description,
          url: videosData.url
        };

        if (videosData.types === 'Tutorial Video') {
          allVideos.push(video);
        }
      }
      setTutorialVideos(allVideos);
      console.log('Tutorial videos updated:', allVideos);

    } catch (error) {
      console.error('Error updating tutorial videos:', error);
    }
  }, [timeDateAddress]);

  useEffect(() => {
    updateVideosUrl();
  }, [updateVideosUrl]);

  return (
    <div className="page-container">
      <h1 className="section-title">Video Tutorials!</h1>
      <div className="video-container">
        {tutorialVideos.map((video, index) => (
          <div className="video-card" key={index}>
            <div className="video-wrapper">
              <img src={ImpactXLogo} alt="ImpactX Logo" className="impactx-logo" />
              <video className="video-player" src={video.url} controls />
            </div>
            <h2 className="video-title">{video.title}</h2>
            <p className="video-description">{video.description}</p>
            <a href="https://www.impactxacademy.com/" className="academy-text" target="_blank" rel="noopener noreferrer">
              ImpactX Academy
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default App;
