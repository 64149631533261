import React, { useState, useEffect, useContext, useCallback } from 'react';
import Web3 from 'web3';
import dataLabAbi from '../ABI/timeABI.json';
import { WalletContext } from '../WalletContext';
import '../styles/MilestonesRewardsCards.css';

const VideoPopup = ({ video, onClose }) => {
  return (
    <div className="video-popup-overlay">
      <div className="video-popup">
        <button className="popup-close-btn" onClick={onClose}>✕</button>
        <video controls autoPlay width="100%">
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

const cardData = [
  {
    title: 'LOVELACE',
    price: 20000,
    rewards: 500,
    features: [
      'Certificate of Achievement',
      'Award Ceremony',
    ],
  },
  {
    title: 'TESLA',
    price: 50000,
    rewards: 1000,
    features: [
      'Certificate of Achievement',
      'Award Ceremony',
    ],
  },
  {
    title: 'TURING',
    price: 100000,
    rewards: 2000,
    features: [
      'Certificate of Achievement',
      'Award Ceremony',
      'All-expenses-paid trip to a tech conference',
    ],
  },
  {
    title: 'JOBS',
    price: 500000,
    rewards: 4000,
    features: [
      'Certificate of Achievement',
      'Award Ceremony',
      'All-expenses-paid trip to a tech conference',
      '$2000 of latest tech gadgets',
    ],
  },
  {
    title: 'SATOSHI',
    price: 1000000,
    rewards: 8000,
    features: [
      'Certificate of Achievement',
      'Award Ceremony',
      'All-expenses-paid trip to a tech conference',
      '$2000 of latest tech gadgets',
    ],
  },
];

const MilestonesRewardsCards = () => {
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const [rewardVideo, setRewardVideo] = useState();
  const { timeDateAddress } = useContext(WalletContext);

  const updateVideosUrl = useCallback(async () => {
    try {
      console.log(`Updating reward videos`);
      const web3Instance = new Web3('https://polygon-mainnet.infura.io/v3/2a8bbfb4590e4950a2b5013900024f08');
      const contractInstance = new web3Instance.eth.Contract(dataLabAbi, timeDateAddress);

      const videosNonce = await contractInstance.methods.videoNonce().call();
      const videosNonceNumber = Number(videosNonce.toString());
      let videoLink;

      for (let i = 1; i <= videosNonceNumber; i++) {
        const videosData = await contractInstance.methods.getVideoData(i).call();
        if (videosData.types === 'Reward Video') {
          videoLink = videosData.url;
        }
      }
      setRewardVideo(videoLink);
      console.log('Reward videos updated:', videoLink);
    } catch (error) {
      console.error('Error updating reward videos:', error);
    }
  }, [timeDateAddress]);

  useEffect(() => {
    const fetchVideoData = async () => {
      await updateVideosUrl();
      setShowVideoPopup(true);
    };
    
    fetchVideoData();
  }, [updateVideosUrl]);

  return (
    <div className="rewards-cards">
      {showVideoPopup && (
        <VideoPopup
          video={rewardVideo}
          onClose={() => setShowVideoPopup(false)}
        />
      )}
      <h2>Milestones Rewards</h2>
      <div className="rewards-container">
        {cardData.map((card, index) => (
          <div key={index} className="rewards-card">
            <h3>{card.title}</h3>
            <p className="rewards-price">${card.price.toLocaleString()}</p>
            <p className="rewards">${card.rewards}</p>
            <ul className="rewards-features">
              {card.features.map((feature, i) => (
                <li key={i} className="active-feature">
                  {feature}
                  <span className="rewards-checkmark">✓</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MilestonesRewardsCards;