import React from 'react';
import '../styles/TermsAndConditions.css';

const TermsAndConditions = () => {
    return (
        <div className="terms-container">
            <h1>Terms & Conditions</h1>
            <h2>Partner Program Terms and Conditions</h2>
            <p>These Affiliate Program Terms and Conditions (the "Agreement") are entered into by and between ImpactX Global, a [Your Company's Legal Structure], whose address is 3709/10 Churchill Tower, Business Bay, Dubai, UAE ("Company"), and the affiliate ("Affiliate") who wishes to participate in the ImpactX Global Affiliate Program (the "Program").</p>

            <h3>1. Program Overview:</h3>
            <p>1.1. The Program enables Affiliates to promote and sell memberships that provide crypto assets, hardware wallets, emerging technology education, NFTs, and earn commissions on successful promotions.</p>
            <p>1.2. Participation in the Program is subject to the terms and conditions outlined in this Agreement.</p>

            <h3>2. Affiliate Registration:</h3>
            <p>2.1. To become an Affiliate, the applicant must complete the registration process on the ImpactX Global website and agree to these terms.</p>
            <p>2.2. ImpactX Global reserves the right to reject any Affiliate application for any reason.</p>

            <h3>3. Promotion and Marketing:</h3>
            <p>3.1. Affiliates may promote ImpactX Global products and services through approved marketing materials provided by the Company.</p>
            <p>3.2. Affiliates agree not to engage in any unethical or illegal marketing practices, including but not limited to spam, false advertising, or misleading promotions.</p>

            <h3>4. Commissions:</h3>
            <p>4.1. Affiliates earn commissions based on qualifying sales generated through their unique affiliate link.</p>
            <p>4.2. Commissions are calculated based on the ImpactX Global commission structure, as outlined on the ImpactX Global website.</p>
            <p>4.3. Commissions are paid on a [monthly/quarterly] basis, with a minimum payout threshold set by ImpactX Global.</p>

            <h3>5. Membership Sales:</h3>
            <p>5.1. Affiliates may promote and sell ImpactX Global memberships that provide access to crypto assets, hardware wallets, emerging technology education, and NFTs.</p>
            <p>5.2. Affiliates are responsible for providing accurate information about ImpactX Global's products and services.</p>

            <h3>6. Confidentiality:</h3>
            <p>6.1. Affiliates agree to keep confidential any proprietary information provided by ImpactX Global, including but not limited to marketing strategies, customer lists, and commission rates.</p>

            <h3>7. Termination:</h3>
            <p>7.1. Either party may terminate this Agreement at any time with or without cause by providing written notice to the other party.</p>
            <p>7.2. ImpactX Global reserves the right to terminate an Affiliate's participation in the Program for violation of these terms.</p>

            <h3>8. Changes to Terms:</h3>
            <p>8.1. ImpactX Global reserves the right to modify these terms and conditions at any time.</p>
            <p>8.2. Affiliates will be notified of any changes, and continued participation in the Program implies acceptance of the modified terms.</p>

            <h3>9. Governing Law:</h3>
            <p>9.1. This Agreement shall be governed by and construed in accordance with the laws of Malta.</p>
            <p>9.2. Any disputes arising under or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts in Malta.</p>

            <p className="acceptance">By participating in the ImpactX Global Affiliate Program, the Affiliate acknowledges that they have read, understood, and agree to be bound by these terms and conditions.</p>

            <p>This Agreement is effective as of 25/01/2024.</p>

            <div className="company-info">
                <p>ImpactX Global</p>
                <p>3709/10 Churchill Tower, Business Bay, Dubai, UAE</p>
                <p>Contact: admin@impactx.ai</p>
            </div>
        </div>
    );
};

export default TermsAndConditions;