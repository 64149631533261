import { React, useRef } from 'react';
import logo from '../assets/logoblack.png';
import emailjs from '@emailjs/browser';
import twiterIcon from '../assets/img/icon/Twitter.png';
import facebookIcon from '../assets/img/icon/facebook.png';
import linkdinIcon from '../assets/img/icon/linkdin.webp';
import telegramIcon from '../assets/img/icon/Telegram.webp';
import youtubeIcon from '../assets/img/icon/youtube.png';
import '../styles/ContactForm.css';

const ContactForm = () => {
    const form = useRef();

    const handleEmailClick = () => {
        window.location.href = `mailto:admin@impactx.ai`;
    };

    const handleWebsiteClick = () => {
        window.location.href = 'https://www.impactx.ai/';
    };

    const handleXClick = () => {
        window.location.href = 'https://x.com/impactxglobal';
    }

    const handleFacebookClick = () => {
        window.location.href = 'https://www.facebook.com/groups/impactxacademycommunity';
    }

    const handleLinkedinClick = () => {
        window.location.href = 'https://www.linkedin.com/company/impactxglobal/';
    }

    const handleTelegramClick = () => {
        window.location.href = 'https://t.me/impactxglobal';
    }

    const handleYoutubeClick = () => {
        window.location.href = 'https://www.youtube.com/@impactxchannel';
    }

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_idvbl4h', 'template_nbx4rf1', form.current, {
                publicKey: 'YLy3OAHfXsPQn5-o9',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    form.current.reset();
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    return (
        <div className='header-text'>
            <div className="contact-form">
                <div className="contact-info">
                    <div className='address'>
                        <h1>IMPACTX GLOBAL</h1>
                        <p>3709/10 Churchill Tower, Business Bay, Dubai, UAE</p>
                    </div>
                    <div>
                        <img className='logo-image' src={logo} />
                    </div>
                    <div className='email-website'>
                        <p className='emai-para'><a onClick={handleEmailClick}>admin@impactx.ai</a></p>
                        <p className='web-para'><a onClick={handleWebsiteClick}>www.impactx.ai</a></p>
                        <div className="round-icon">
                            <img className="icon-logo" src={twiterIcon} alt="Logo" onClick={handleXClick} />
                            <img className="icon-logo" src={facebookIcon} alt="Logo" onClick={handleFacebookClick} />
                            <img className="icon-logo" src={linkdinIcon} alt="Logo" onClick={handleLinkedinClick} />
                            <img className="icon-logo" src={telegramIcon} alt="Logo" onClick={handleTelegramClick} />
                            <img className="icon-logo" src={youtubeIcon} alt="Logo" onClick={handleYoutubeClick} />
                        </div>
                    </div>
                </div>
                <div className="form-container">
                    <form ref={form} onSubmit={sendEmail}>
                        <div className="form-group">
                            <label>Name</label>
                            <input type="text" name="user_name" placeholder='Enter name' />
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input type="email" name="user_email" placeholder='Enter your email' />
                        </div>
                        <div className="form-group">
                            <label>Message</label>
                            <textarea name="message" placeholder='Enter message' />
                        </div>
                        <div className="send-message">
                            <button type="submit" value="Send">Send message</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ContactForm;