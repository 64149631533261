import {React, useContext} from 'react';
import { WalletContext } from '../WalletContext';
import '../styles/AllTimeStats.css';

const AllTimeStats = () => {
  const {todaysEarning, totalRefferals, thisMonthEarning, totalEarning, thisYearEarning} = useContext(WalletContext);
  console.log("All Time Data:", {todaysEarning, totalRefferals, thisMonthEarning, totalEarning, thisYearEarning});
  return (
    <div className="all-time-stats">
      <h2>ALL-TIME</h2>
      <div className="stats-grid">
        <div className="stat-card">
          <div className="stat-icon">$</div>
          <div className="stat-info">
            <h3>Total Referrals</h3>
            <p className="stat-value">{totalRefferals}</p>
          </div>
        </div>

        <div className="stat-card">
          <div className="stat-icon">$</div>
          <div className="stat-info">
            <h3>Earning This Year</h3>
            <p className="stat-value">${thisYearEarning}</p>
          </div>
        </div>

        <div className="stat-card">
          <div className="stat-icon">$</div>
          <div className="stat-info">
            <h3>Member Referrals</h3>
            <p className="stat-value">{totalRefferals}</p>
          </div>
        </div>

        <div className="stat-card">
          <div className="stat-icon">$</div>
          <div className="stat-info">
            <h3>Today’s Earning</h3>
            <p className="stat-value">${todaysEarning}</p>
          </div>
        </div>

        <div className="stat-card">
          <div className="stat-icon">$</div>
          <div className="stat-info">
            <h3>Monthly Referrals</h3>
            <p className="stat-value">${thisMonthEarning}</p>
          </div>
        </div>

        <div className="stat-card">
          <div className="stat-icon">$</div>
          <div className="stat-info">
            <h3>Total Earnings</h3>
            <p className="stat-value">${totalEarning}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllTimeStats;